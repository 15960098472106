import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import WashupHeader from "../dashboard/header/washupHeader";

import washer from "../../../../assets/images/machines/washup-washer.png";
import dryer from "../../../../assets/images/machines/washup-dryer.png";
import { currency } from "../../../../dynamicController";

const WashupTransaction = ({ user, setToggled, selected, setSelected, renderDate, transactions, reloads, transfers, points }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div>
            <WashupHeader user={user} setToggled={setToggled} />

            <div id="user-body">
                <p className="text-center pt-2">{selected === "Transaction" ? t("Only up to last 3 months transaction history") : selected === "Reload" ? t("Only up to last 3 months reload history") : selected === "Transfer" ? t("Only up to last 3 months transfer history") : selected === "Point" ? <Trans i18nKey="Only up to last 3 months {{ point }} history">Only up to last 3 months {{ point: user.loyalty_point_name }} history</Trans> : ""}</p>

                {
                    user.loyalty_point || user.tokenSetting ? (
                        <div className="d-flex justify-content-around px-3">
                            <button id="transaction-page-transaction" onClick={() => setSelected("Transaction")} className={selected === "Transaction" ? "yellow-button" : "blue-button"}>
                                {t("Transaction")}
                            </button>

                            {
                                user.reload ? (
                                    <button id="transaction-page-reload" onClick={() => setSelected("Reload")} className={selected === "Reload" ? "yellow-button" : "blue-button"}>
                                        {t("Reload")}
                                    </button>
                                ) : null
                            }

                            {
                                user.transfer ? (
                                    <button id="transaction-page-transfer" onClick={() => setSelected("Transfer")} className={selected === "Transfer" ? "yellow-button" : "blue-button"}>
                                        {t("Transfer")}
                                    </button>
                                ) : null
                            }

                            {
                                user.loyalty_point ? (
                                    <button id="transaction-page-point" onClick={() => setSelected("Point")} className={selected === "Point" ? "yellow-button" : "blue-button"}>
                                        {user.loyalty_point_name}
                                    </button>
                                ) : null
                            }
                        </div>
                    ) : null
                }

                <div className="stamp-component-cp mt-3">
                    {
                        selected === "Transaction" ? (
                            transactions.map(transaction => {
                                return (
                                    <div key={transaction.id} className="transaction-list" onClick={() => transaction.transaction_type === "Package" ? history.push("/user/dng/pack/details", { transaction }) : ""}>
                                        <div className="img-container">
                                            <img src={transaction.machine_type === "Washer" ? washer : dryer} alt="machine" className="img-fluid" />
                                        </div>

                                        <div className="info-container">
                                            <div>
                                                {
                                                    transaction.transaction_type === "Package" ? (
                                                        <div>
                                                            <h5 className="font-weight-bold m-0 p-0">{t("Drop n Go Service")}</h5>
                                                            <p style={{ color: "grey", fontWeight: "bold" }}>{transaction.outlet.outlet_name}</p>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <h5 className="font-weight-bold m-0 p-0">{transaction.machine_name}</h5>
                                                            <p style={{ color: "grey", fontWeight: "bold" }}>{transaction.machine_capacity}</p>
                                                        </div>
                                                    )
                                                }
                                                <h6 className="font-weight-bold m-0 p-0">{renderDate(transaction.createdAt)}</h6>
                                            </div>

                                            <div className="text-right">
                                                <h5 style={{ color: transaction.status === "Success" ? "green" : transaction.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(transaction.status)}</h5>
                                                <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                                                    {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                    {transaction.amount + transaction.sms_amount}
                                                    {transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}
                                                </h5>
                                                {transaction.voucherId ? (
                                                    transaction.voucher.type === "Flat" ? (
                                                        <h5 className="font-weight-bold">
                                                            - {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                            {transaction.voucher.amount}
                                                            <small style={{ fontWeight: "" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                                                        </h5>
                                                    ) : (
                                                        <h5 className="font-weight-bold">- {transaction.voucher.amount} %</h5>
                                                    )
                                                ) : transaction.amount !== transaction.payment_amount ? (
                                                    <h5 className="font-weight-bold">
                                                        - {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                        {(transaction.amount - transaction.payment_amount).toFixed(2)}
                                                        <small style={{ fontWeight: "bold", fontSize: "10px" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                                                    </h5>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : selected === "Reload" ? (
                            reloads.map(reload => {
                                return (
                                    <div key={reload.id} className="transaction-list">
                                        <div className="img-container" style={{ backgroundColor: "#004c64", borderRadius: "50%" }}>
                                            <i className="fas bi-currency-dollar" style={{ fontSize: "40px", marginTop: "10px", color: "#CFA82C" }}></i>
                                        </div>

                                        <div className="info-container">
                                            <div>
                                                <h5 className="font-weight-bold">{currency(user.country)} {reload.amount}</h5>
                                                <h6 className="font-weight-bold">{renderDate(reload.createdAt)}</h6>
                                            </div>

                                            <div className="text-right">
                                                <h5 style={{ color: reload.status === "Success" ? "green" : reload.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(reload.status)}</h5>
                                                <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                                                    {reload.token} {t("E-TOKEN")}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : selected === "Transfer" ? (
                            transfers.map(transfer => {

                                return (
                                    <div key={transfer.id} className="transaction-list">
                                        <div className="img-container" style={{ backgroundColor: "#004c64", borderRadius: "50%" }}>
                                            <i className="fas bi-arrow-left-right" style={{ fontSize: "30px", marginTop: "15px", color: "#CFA82C" }}></i>
                                        </div>

                                        <div className="info-container">
                                            <div>
                                                {
                                                    transfer.amount > 0 ? (
                                                        <div>
                                                            <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_from.number}</h5>
                                                            <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_from.name}</p>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_to.number}</h5>
                                                            <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_to.name}</p>
                                                        </div>
                                                    )
                                                }
                                                <h6 className="font-weight-bold">{renderDate(transfer.createdAt)}</h6>
                                            </div>

                                            <div className="text-right">
                                                <h5 style={{ color: transfer.amount > 0 ? "green" : "red", fontWeight: "bold" }}>
                                                    {transfer.amount} {t("E-TOKEN")}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : selected === "Point" ? (
                            points.map(point => {

                                return (
                                    <div key={point.id} className="transaction-list">
                                        <div className="img-container" style={{ backgroundColor: "#004c64", borderRadius: "50%" }}>
                                            <i className="fas bi-currency-exchange" style={{ fontSize: "30px", marginTop: "15px", color: "#CFA82C" }}></i>
                                        </div>

                                        <div className="info-container">
                                            <div>
                                                <h5 className="font-weight-bold">{t(point.type)}</h5>
                                                <h6 className="font-weight-bold">{renderDate(point.createdAt)}</h6>
                                            </div>

                                            <div className="text-right">
                                                <h5 style={{ color: point.amount > 0 ? "green" : "red", fontWeight: "bold" }}>
                                                    {point.amount} {user.loyalty_point_name}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default WashupTransaction;